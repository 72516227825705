import React, { useEffect, useState } from "react";
import { Stack, Text, CommandBarButton } from "@fluentui/react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import styles from "./UserProfile.module.css";
import {
  getallcompanies,
  getallindustries,
  getalllocations,
  getUserProfileDetails,
  updateUserProfileDetails,
} from "../../api";
import { UserProfileDetails } from "../../api/models";
import { Loader } from "../CarbonAIChat/common/Common";

export const UserProfile = () => {
  const maxOptions = 5; // Maximum number of options to display
  const [formattedAreasOfInterest, setFormattedAreasOfInterest] =
    useState<string>("");

  const [industryOptions, setIndustryOptions] = useState<{ name: string }[]>(
    []
  );
  const [companyerror, setcompanyerror] = useState<string>("");
  const [worklocationerror, setworklocationerror] = useState<string>("");
  const [userlocationerror, setuserlocationerror] = useState<string>("");
  const [keyinterestserror, setkeyinterestserror] = useState<string>("");
  const [userprofile, setUserProfile] = useState<UserProfileDetails>();
  const [keyinterests, setKeyInterests] = useState<string>("");
  const [company, setCompany] = useState<string>(""); // Company selection
  const [companyOptions, setCompanyOptions] = useState<{ name: string }[]>([]);
  const [locationoptions, setLocationOptions] = useState<{ name: string }[]>(
    []
  );
  const [worklocation, setLocation] = useState<string>(""); // Location selection
  const [userlocation, setUserLocation] = useState<string>(""); // Location selection
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]); // Industries selection
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]); // Countries selection
  const [personalization, setPersonalization] = useState<boolean>(true);

  const [loading, setLoading] = useState(true); // Loading state to show loader before user profile is fetched
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  // Handle company change from MUI Autocomplete
  const handleCompanyChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue) {
      if (newValue.length > 255) {
        setcompanyerror("Company name cannot exceed 255 characters");

        setTimeout(() => {
          setcompanyerror("");
        }, 2000); // 2000 milliseconds = 2 seconds

        return;
      }
      setCompany(newValue); // Update state with the selected company
    }
    setcompanyerror("");
  };

  const handleCompanyInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 255) {
      setcompanyerror("Company name cannot exceed 255 characters");
      return;
    }
    setcompanyerror(null); // Clear error if input is valid
    setCompany(newInputValue); // Update state with user-typed value
  };

  // Handle work location change from MUI Autocomplete
  const handleWorkLocationChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue) {
      if (newValue.length > 255) {
        setworklocationerror("Work location cannot exceed 255 characters");
        return;
      }
      setworklocationerror("");
      setLocation(newValue); // Update state with the selected location
    }
  };

  const handleWorkLocationInputChange = (
    // Handle user input
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 255) {
      setworklocationerror("Work location cannot exceed 255 characters");
      return;
    }
    setworklocationerror("");
    setLocation(newInputValue); // Update state with user-typed value
  };

  // Handle industry change for Multi-select
  const handleIndustryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedIndustries(typeof value === "string" ? value.split(",") : value);
  };

  // Handle country change for Multi-select
  const handleCountryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedCountries(typeof value === "string" ? value.split(",") : value);
  };

  // Handle user location change from MUI Autocomplete
  const handleUserLocationChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue) {
      if (newValue.length > 255) {
        setuserlocationerror("User Location cannot exceed 255 characters");
        return;
      }
      setUserLocation(newValue); // Update state with the selected location
      setuserlocationerror("");
    }
  };

  const handleUserLocationInputChange = (
    // Handle user input
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 255) {
      setuserlocationerror("User Location cannot exceed 255 characters");
      return;
    }
    setUserLocation(newInputValue); // Update state with user-typed value
    setuserlocationerror("");
  };

  const handleKeyInterestsChange = (value: string) => {
    console.log(value.length);
    if (value.length > 255) {
      setkeyinterestserror(
        "Key Areas of Interest cannot exceed 255 characters"
      );

      setTimeout(() => {
        setcompanyerror("");
      }, 2000); // 2000 milliseconds = 2 seconds
      return;
    }
    setKeyInterests(value);
    setkeyinterestserror("");
  };

  const handlePersonizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPersonalization(event.target.checked);
  };

  // Function to determine how many options to display based on screen width
  const getVisibleOptionCount = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 1100) {
      return 1; // Show only 1 option for mobile
    } else if (screenWidth < 1600) {
      return 2; // Show 3 options for larger screens
    } else {
      return 5; // Show 5 options for larger screens
    }
  };

  const getVisibleOptionCountCountry = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 900) {
      return 1; // Show only 3 option for mobile
    } // Show 5 options for tablets
    else if (screenWidth < 1200) {
      return 2; // Show 9 options for larger screens
    } else if (screenWidth < 1600) {
      return 3; // Show 9 options for larger screens
    } else {
      return 4; // Show 9 options for larger screens
    }
  };

  const formatKeyInterests = (input: string): { [key: string]: string[] } => {
    if (!input.trim()) return {}; // Handle empty input

    const interestsArray = input.split(",").map((interest) => interest.trim()); // Split and trim each interest

    // Assuming you want to group all topics under a generic "Topic"
    return {
      Topic: interestsArray, // Assign all interests to "Topic"
    };
  };
  const handleSave = () => {
    // Save user profile data

    const updatedProfile = {
      ...userprofile, // Spread the existing user profile
      areas_of_interest: formatKeyInterests(keyinterests),
      company: company,
      work_location: worklocation,
      user_location: userlocation,
      industry: selectedIndustries,
      governing_law_country: selectedCountries,
      personalization: personalization,
    };

    updateUserProfileDetails(updatedProfile).then((response) => {
      if (response.ok) {
        fetchUserProfile(); // Fetch updated user profile
        setModalMsg("User profile updated successfully");
        setShowModal(true);
      } else {
        setModalMsg("Failed to update user profile");
        setShowModal(true);
      }
    });
  };

  // Fetch data after component mounts
  useEffect(() => {
    const fetchInitialData = async () => {
      // Fetch companies, locations, industries
      const fetchCompanies = async () => {
        const response = await getallcompanies();
        if (response.ok) {
          const data = await response.json();
          setCompanyOptions(data);
        }
      };
      const fetchLocations = async () => {
        const response = await getalllocations();
        if (response.ok) {
          const data = await response.json();
          setLocationOptions(data);
        }
      };
      const fetchIndustries = async () => {
        const response = await getallindustries();
        if (response.ok) {
          const data = await response.json();
          setIndustryOptions(data);
        }
      };

      await Promise.all([
        fetchCompanies(),
        fetchLocations(),
        fetchIndustries(),
      ]);
    };

    fetchInitialData();

    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const response = await getUserProfileDetails();
    if (response.ok) {
      const data = await response.json();
      setUserProfile(data);
      const areasOfInterestArray = Object.values(
        data.areas_of_interest || {}
      ).flat();
      const areasOfInterestString = areasOfInterestArray.join(", ");

      setKeyInterests(areasOfInterestString); // Set the comma-separated string
      setCompany(data.company || ""); // Safely access company
      setLocation(data.work_location || ""); // Safely access work_location
      setUserLocation(data.user_location || ""); // Safely access user_location
      setSelectedIndustries(data.industry ? data.industry : []); // Safely access industry
      setSelectedCountries(
        data.governing_law_country ? data.governing_law_country : []
      ); // Safely access governing_law_country

      const formattedAreas = Object.entries(data.gen_areas_of_interest || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
      setFormattedAreasOfInterest(formattedAreas);
      setPersonalization(
        data.personalization !== undefined ? data.personalization : true
      );
    }
    setLoading(false); // Stop loading once data is fetched
  };

  if (loading) {
    // Show loader until user profile is fetched
    return <Loader />;
  }
  return (
    <div className={`${styles.padding20}`}>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Modal Header */}
        <Stack.Item>
          <Text
            role="heading"
            aria-level={2}
            className={`${styles.userProfileLabel}`}
          >
            User Profile
          </Text>
        </Stack.Item>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          className={`${styles.width100}`}
        >
          <Stack.Item grow className={`${styles.flex1}`}>
            <Autocomplete
              options={companyOptions.map((option) => option.name)} // Extract company names
              value={company} // Selected company value
              onChange={handleCompanyChange} // Handle selection
              onInputChange={handleCompanyInputChange} // Handle user input
              freeSolo
              filterOptions={(options, { inputValue }) => {
                if (!inputValue.trim()) {
                  return options.slice(0, maxOptions); // Return top options if input is empty
                }

                const filteredOptions = options.filter(
                  (option) =>
                    option
                      .toLowerCase()
                      .startsWith(inputValue.trim().toLowerCase()) // Trim input value and match case-insensitive
                );

                return filteredOptions.slice(0, maxOptions); // Limit to maxOptions (e.g., 10)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Where I Work (Company)"
                  placeholder="Type or select company"
                  fullWidth
                  inputProps={{
                    ...params.inputProps, // Pass down default props
                    maxLength: 255, // Set the max length here
                  }}
                />
              )}
            />

            <div className={`${styles.error}`}>{companyerror}</div>
          </Stack.Item>

          <Stack.Item grow className={`${styles.flex1}`}>
            {/* Autocomplete for Work Location */}
            <Autocomplete
              options={locationoptions.map((option) => option.name)} // Extract location names
              value={worklocation} // Selected location value
              onChange={handleWorkLocationChange} // Handle selection
              onInputChange={handleWorkLocationInputChange} // Handle user input
              freeSolo
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options.filter(
                  (option) =>
                    option.toLowerCase().startsWith(inputValue.toLowerCase()) // Filter based on input value
                );
                return filteredOptions.slice(0, maxOptions); // Limit the displayed options to maxOptions (e.g., 10)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Work Location"
                  placeholder="Type or select work location"
                  fullWidth
                  inputProps={{
                    ...params.inputProps, // Pass down default props
                    maxLength: 255, // Set the max length here
                  }}
                />
              )}

              // Error message
            />
            <div className={`${styles.error}`}>{worklocationerror}</div>
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          className={`${styles.width100}`}
        >
          <Stack.Item grow className={`${styles.flex1}`}>
            {/* Autocomplete for User Location */}
            <Autocomplete
              options={locationoptions.map((option) => option.name)} // Extract user location names
              value={userlocation} // Selected user location value
              onChange={handleUserLocationChange} // Handle selection
              onInputChange={handleUserLocationInputChange} // Handle user input
              freeSolo
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options.filter(
                  (option) =>
                    option.toLowerCase().startsWith(inputValue.toLowerCase()) // Filter based on input value
                );
                return filteredOptions.slice(0, maxOptions); // Limit the displayed options to maxOptions (e.g., 10)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Location"
                  placeholder="Type or select user location"
                  fullWidth
                  inputProps={{
                    ...params.inputProps, // Pass down default props
                    maxLength: 255, // Set the max length here
                  }}
                />
              )}
            />
            <div className={`${styles.error}`}>{userlocationerror}</div>
          </Stack.Item>

          <Stack.Item grow className={`${styles.flex1}`}>
            {/* Material-UI Multi-select for Industry */}
            <FormControl fullWidth>
              <InputLabel id="industry-select-label">
                Industry/Sector
              </InputLabel>
              <Select
                labelId="industry-select-label"
                multiple
                value={selectedIndustries ? selectedIndustries : []} // Selected industries
                onChange={handleIndustryChange} // Handle selection
                renderValue={(selected) => {
                  const visibleCount = getVisibleOptionCount(); // Get visible count based on screen width
                  const limitedSelection = selected
                    .slice(0, visibleCount)
                    .join(", ");
                  const remainingCount = selected.length - visibleCount;
                  return selected.length > visibleCount
                    ? `${limitedSelection}+${remainingCount}`
                    : limitedSelection;
                }}
                fullWidth // Make responsive
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set the max height for the dropdown (in px)
                      width: "40%", // Set the width to auto
                    },
                  },
                }}
              >
                {industryOptions.map((industry) => (
                  <MenuItem key={industry.name} value={industry.name}>
                    <Checkbox
                      checked={selectedIndustries.indexOf(industry.name) > -1}
                    />
                    <ListItemText primary={industry.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          className={`${styles.width100}`}
        >
          <Stack.Item grow className={`${styles.flex1}`}>
            {/* Material-UI Multi-select for Governing Law Country */}
            <FormControl fullWidth>
              <InputLabel id="country-select-label">
                Governing Law Country
              </InputLabel>
              <Select
                labelId="country-select-label"
                multiple
                value={selectedCountries ? selectedCountries : []} // Selected countries
                onChange={handleCountryChange} // Handle selection
                renderValue={(selected) => {
                  const visibleCount = getVisibleOptionCountCountry(); // Get visible count based on screen width
                  const limitedSelection = selected
                    .slice(0, visibleCount)
                    .join(", ");
                  const remainingCount = selected.length - visibleCount;
                  return selected.length > visibleCount
                    ? `${limitedSelection}+${remainingCount}`
                    : limitedSelection;
                }}
                fullWidth // Make responsive
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set the max height for the dropdown (in px)
                      width: "50%",
                    },
                  },
                }}
              >
                {locationoptions.map((location) => (
                  <MenuItem key={location.name} value={location.name}>
                    <Checkbox
                      checked={selectedCountries.indexOf(location.name) > -1}
                    />
                    <ListItemText primary={location.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item>
            <Stack.Item grow className={`${styles.flex1}`}>
              <TextField
                label="Key Areas of Interest"
                value={keyinterests}
                onChange={(e) => handleKeyInterestsChange(e.target.value)}
                fullWidth
                multiline
                inputProps={{
                  maxLength: 255, // Set the max length here
                }}
                rows={3}
                placeholder="Enter interests, separated by commas"
              />
              <div className={`${styles.error}`}>{keyinterestserror}</div>
            </Stack.Item>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item className={`${styles.width100}`}>
            <TextField
              label="Current Areas of Interest"
              value={formattedAreasOfInterest} // Display the current areas of interest
              multiline
              rows={5}
              InputProps={{ readOnly: true }} // Read-only mode
              fullWidth
            />
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={personalization} // Bind the checkbox to state
                  onChange={handlePersonizationChange} // Handle state change
                />
              }
              label="Activate Personalization"
            />
          </Stack.Item>
          <Stack.Item align="end" className={`${styles.marginTop20}`}>
            <CommandBarButton
              role="button"
              styles={{
                icon: {
                  color: "var(--default)",
                },
                iconDisabled: {
                  cursor: "not-allowed",
                  color: "var(--secondary)",
                  opacity: "30%",
                },
                root: {
                  color: "var(--default)",
                  backgroundColor: "var(--secondary)",
                },
                rootDisabled: {
                  backgroundColor: "var(--secondary)",
                  color: "var(--default)",
                  cursor: "not-allowed",
                  opacity: "30%",
                },
                rootHovered: {
                  backgroundColor: "var(--secondary)",
                  color: "var(--default)",
                },
                iconHovered: {
                  color: "var(--default)",
                },
              }}
              className={styles.newChatIcon}
              text={"Save"}
              onClick={handleSave}
              aria-label="Save Profile"
              title={"Save Profile"}
            />
          </Stack.Item>
        </Stack>
        {showModal && (
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            centered
          >
            <Modal.Body>{modalMsg}</Modal.Body>
            <Modal.Footer>
              <Button
                className={styles.popBtn}
                variant="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Stack>
    </div>
  );
};
